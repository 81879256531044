import "./App.css";
import PageContent from "./Components/PageContent";

function App() {
  return (
    <div>
      <PageContent></PageContent>
    </div>
  );
}

export default App;
